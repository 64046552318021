<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">信贷保镖记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div v-if="data">
      <radar-block title="贷前反欺诈">
        <el-row>
          <el-col :span="6"><div class="grid-content bg-purple">风险分数：</div></el-col>
          <el-col :span="6"><div class="grid-content bg-purple-light">{{ data.final_score }}</div></el-col>
          <el-col :span="6"><div class="grid-content bg-purple">决策结果：</div></el-col>
          <el-col :span="6"><div class="grid-content bg-purple-light">{{ data.final_decision }}</div></el-col>
      </el-row>
      </radar-block>
      <radar-block title="风险项目" style="margin-top: 20px;">
        <div v-for="(riskItem, itemIndex) in data.risk_items" :key="itemIndex">
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple">规则名称：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.risk_name }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">风险分数：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.score }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">决策结果：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.decision }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">策略决策结果：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.policy_decision }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">策略模式：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.policy_mode }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">策略分数：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.policy_score }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">策略名称：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.policy_name }}</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">风险等级：</div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">{{ riskItem.rule_level }}</div>
            </el-col>
          </el-row>
          <radar-block title="风险详情">
            <div v-for="(riskDetail, detailIndex) in riskItem.risk_detail" :key="detailIndex">
              <!--风险群体风险详情表-->
              <div v-if="riskDetail.type === 'suspected_team'">
                <div v-for="(team, teamIndex) in riskDetail.suspect_team_detail_list" :key="teamIndex">
                  <span>匹配字段名称：{{ team.dim_type }}</span>&nbsp;&nbsp;&nbsp;
                  <span>匹配字段值：{{ team.dim_value }}</span>&nbsp;&nbsp;&nbsp;
                  <span>疑似风险群体编号：{{ team.group_id }}</span>&nbsp;&nbsp;&nbsp;
                  <span>疑似风险群体成员数：{{ team.total_cnt }}</span>&nbsp;&nbsp;&nbsp;
                  <span>风险名单成员数：{{ team.black_cnt }}</span>&nbsp;&nbsp;&nbsp;
                  <span>关注名单成员数：{{ team.grey_cnt }}</span>&nbsp;&nbsp;&nbsp;
                  <span>成员分布：{{ team.node_dist }}</span>&nbsp;&nbsp;&nbsp;
                  <span>风险名单分布：{{ team.fraud_dist }}</span>&nbsp;&nbsp;&nbsp;
                  <span>风险名单占比：{{ team.black_rat }}</span>&nbsp;&nbsp;&nbsp;
                  <span>关注名单占比：{{ team.grey_rat }}</span>&nbsp;&nbsp;&nbsp;
                  <span>一度关联节点个数：{{ team.degree }}</span>&nbsp;&nbsp;&nbsp;
                  <span>二度关联节点个数：{{ team.total_cnt_two }}</span>&nbsp;&nbsp;&nbsp;
                  <span>一度风险名单个数：{{ team.black_cnt_one }}</span>&nbsp;&nbsp;&nbsp;
                  <span>一度风险名单分布：{{ team.fraud_dist_one }}</span>&nbsp;&nbsp;&nbsp;
                  <span>二度风险名单个数：{{ team.black_cnt_two }}</span>&nbsp;&nbsp;&nbsp;
                  <span>二度风险名单分布：{{ team.fraud_dist_two }}</span>&nbsp;&nbsp;&nbsp;
                  <span>风险节点距离：{{ team.black_dst }}</span>&nbsp;&nbsp;&nbsp;
                  <span>核心节点距离：{{ team.core_dst }}</span>&nbsp;&nbsp;&nbsp;
                  <span>关联风险分：{{ team.node_score }}</span>
                </div>
              </div>
              <!--关注名单风险详情表-->
              <div v-if="riskDetail.type === 'grey_list'">
                关注名单风险详情表
                <span>规则描述：{{ riskDetail.description }}</span>&nbsp;&nbsp;&nbsp;
                <span>规则命中：{{ riskDetail.hit_type_display_name }}</span>
              </div>
              <!--模糊证据库风险详情表-->
              <div v-if="riskDetail.type === 'fuzzy_black_list'">
                模糊证据库风险详情表
                <span>规则描述：{{ riskDetail.description }}</span>
                <span>规则命中：{{ riskDetail.hit_type_display_name }}</span>
                <div v-for="(fuzzy, fuzzyIndex) in riskDetail.fuzzy_list_details" :key="fuzzyIndex">
                  <span>逾期金额区间：{{ fuzzy.overdue_amount_range }}</span>&nbsp;&nbsp;&nbsp;
                  <span>逾期笔数：{{ fuzzy.overdue_count }}</span>&nbsp;&nbsp;&nbsp;
                  <span>逾期时间区间：{{ fuzzy.overdue_day_range }}</span>&nbsp;&nbsp;&nbsp;
                  <span>逾期入库时间：{{ fuzzy.overdue_time }}</span>
                </div>
              </div>
              <!--频度风险详情表-->
              <div v-if="riskDetail.type === 'frequency_detail'">
                <div v-for="(frequency, frequencyIndex) in riskDetail.frequency_detail_list" :key="frequencyIndex">
                  <el-row>
                  <el-col :span="12"><div class="grid-content bg-purple">规则描述：{{ frequency.note }}</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple-light">规则命中：{{ frequency.detail }}</div></el-col>
                </el-row>
                </div>
              </div>
              <!--跨事件频度风险详情表-->
              <div v-if="riskDetail.type === 'cross_frequency_detail'">
                跨事件频度风险详情表
                <div v-for="(cross, crossIndex) in riskDetail.cross_frequency_detail_list" :key="crossIndex">
                  <el-row>
                    <el-col :span="12"><div class="grid-content bg-purple">规则描述：{{ cross.note }}</div></el-col>
                    <el-col :span="12"><div class="grid-content bg-purple-light">规则命中：{{ cross.detail }}</div></el-col>
                </el-row>
                </div>
              </div>
              <!--跨事件字段风险详情表-->
              <div v-if="riskDetail.type === 'cross_event_detail'">
                跨事件字段风险详情表
                <div v-for="(event, eventIndex) in riskDetail.cross_event_detail_list" :key="eventIndex">
                  <el-row>
                    <el-col :span="12"><div class="grid-content bg-purple">规则描述：{{ event.note }}</div></el-col>
                    <el-col :span="12"><div class="grid-content bg-purple-light">规则命中：{{ event.detail }}</div></el-col>
                </el-row>
                </div>
              </div>
              <!--自定义列表风险详情表-->
              <div v-if="riskDetail.type === 'custom_list'">
                自定义列表风险详情表（需要继续补充）
                <span>规则描述：{{ riskDetail.description }}</span>
              </div>
              <!--多平台风险详情表-->
              <div v-if="riskDetail.type === 'platform_detail'">
                多平台风险详情表
                <el-row>
                    <el-col :span="12"><div class="grid-content bg-purple">规则描述：{{ riskDetail.description }}</div></el-col>
                    <el-col :span="12"><div class="grid-content bg-purple-light">平台个数：{{ riskDetail.platform_count }}</div></el-col>
                </el-row>
              </div>
              <!--风险名单风险详情表-->
              <div v-if="riskDetail.type === 'black_list'">
                风险名单风险详情表
                <span>规则描述：{{ riskDetail.description }}</span>&nbsp;&nbsp;&nbsp;
                <span>规则命中：{{ riskDetail.hit_type_display_name }}</span>
              </div>
              <!--设备状态异常风险详情表-->
              <div v-if="riskDetail.type === 'device_status_abnormal'">
                设备状态异常风险详情表
                <div v-for="(device, deviceIndex) in riskDetail.abnormal_tags" :key="deviceIndex">
                  <span>疑似刷机：{{ device.SUSPECTED_OF_WIPING_PHONE }}</span>&nbsp;&nbsp;&nbsp;
                  <span>开机时间过短：{{ device.SHORT_UPTIME }}</span>&nbsp;&nbsp;&nbsp;
                  <span>本机时间异常：{{ device.ABNORMAL_TIME }}</span>&nbsp;&nbsp;&nbsp;
                  <span>设备首次出现：{{ device.DEVICE_FIRST_SEEN }}</span>&nbsp;&nbsp;&nbsp;
                  <span>运营商异常：{{ device.ABNORMAL_CARRIER_INFO }}</span>&nbsp;&nbsp;&nbsp;
                  <span>网络信息异常：{{ device.ABNORMAL_NETWORK_CONNECTION }}</span>&nbsp;&nbsp;&nbsp;
                  <span>客户端ID异常：{{ device.ILLEGAL_CLIENTID }}</span>&nbsp;&nbsp;&nbsp;
                  <span>设备为模拟器：{{ device.ANDROID_EMULATOR }}</span>&nbsp;&nbsp;&nbsp;
                  <span>多开：{{ device.MULTIPLE_RUNNING }}</span>&nbsp;&nbsp;&nbsp;
                  <span>检测到调试器：{{ device.DEBUGGER_DETECTED }}</span>&nbsp;&nbsp;&nbsp;
                  <span>检测到改机工具：{{ device.HOOK_TOOL_DETECTED }}</span>&nbsp;&nbsp;&nbsp;
                  <span>设备参数被篡改：{{ device.DEVICE_INFO_TAMPERED }}</span>&nbsp;&nbsp;&nbsp;
                  <span>疑似伪造基站定位：{{ device.SUSPECTED_OF_FAKING_LOCATION }}</span>&nbsp;&nbsp;&nbsp;
                  <span>疑似伪造无线网络信：{{ device.SUSPECTED_OF_FAKING_WIFI }}</span>
                </div>
              </div>
              <!--设备指纹异常风险详情表-->
              <div v-if="riskDetail.type === 'fp_exception'">
                设备指纹异常风险详情表
                <span>异常代码：{{ riskDetail.code }}</span>&nbsp;&nbsp;&nbsp;
                <span>代码名称：{{ riskDetail.code_display_name }}</span>
              </div>
              <!--函数工具箱风险详情表-->
              <div v-if="riskDetail.type === 'function_kit'">
                函数工具箱风险详情表
                <span>计算结果：{{ riskDetail.result }}</span>
              </div>
              <!--信贷名单风险详情表-->
              <div v-if="riskDetail.type === 'creditList_index_detail'">
                信贷名单风险详情表
                <span>匹配字段：{{ riskDetail.dim_type }}</span>&nbsp;&nbsp;&nbsp;
                <span>计算结果：{{ riskDetail.result }}</span>
                <div v-for="(hits, hitsIndex) in riskDetail.hits" :key="hitsIndex">
                  <span>风险时间：{{ hits.evidence_time }}</span>&nbsp;&nbsp;&nbsp;
                  <span>逾期金额：{{ hits.overdue_amount }}</span>&nbsp;&nbsp;&nbsp;
                  <span>逾期期数：{{ hits.overdue_counts }}</span>&nbsp;&nbsp;&nbsp;
                  <span>逾期天数：{{ hits.overdue_days }}</span>&nbsp;&nbsp;&nbsp;
                  <span>来源行业：{{ hits.industry }}</span>&nbsp;&nbsp;&nbsp;
                  <span>主体类型：{{ hits.subject_type }}</span>&nbsp;&nbsp;&nbsp;
                  <span>角色：{{ hits.role }}</span>
                </div>
              </div>
              <!--四则运算风险详情表-->
              <div v-if="riskDetail.type === 'four_calculate'">
                四则运算风险详情表
                <span>计算结果：{{ riskDetail.result }}</span>
              </div>
              <!--关联风险分详情表-->
              <div v-if="riskDetail.type === 'assoc_risk_score'">
                关联风险分详情表
                <span>匹配字段：{{ riskDetail.dim_type }}</span>&nbsp;&nbsp;&nbsp;
                <span>综合关联风险分：{{ riskDetail.node_score }}</span>&nbsp;&nbsp;&nbsp;
                <span>计算结果：{{ riskDetail.dim_value }}</span>
                <div v-for="(assoc, assocIndex) in riskDetail.assoc_risk_score_detail_list" :key="assocIndex">
                  <span>匹配字段：{{ assoc.dim_type }}</span>&nbsp;&nbsp;&nbsp;
                  <span>计算结果：{{ assoc.dim_value }}</span>&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <!--离线指标详情表-->
              <div v-if="riskDetail.type === 'indicatrix_result'">
                离线指标详情表
                <div v-for="(indicatrix, indicatrixIndex) in riskDetail.indicatrix_result_detail_list" :key="indicatrixIndex">
                  <span>规则描述：{{ indicatrix.description }}</span>&nbsp;&nbsp;&nbsp;
                  <span>指标结果：{{ indicatrix.result }}</span>
                </div>
              </div>
            </div>
          </radar-block>
        </div>
      </radar-block>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import radarBlock from "./radar-block"

export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock },
  methods: {
    ...mapActions("userReport/bodyguard", ["getBodyguard"]),
    load (refresh = false) {
      this.getBodyguard(refresh).then(res => res && this.getReports());
    }
  },
  computed: {
    ...mapGetters("userReport/bodyguard", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/bodyguard", ["date"])
  },
  created () {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}

.col {
  margin: 10px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {

  }
  .bg-huidu{
    background: #b4bdc8;
  }
  .bg-purple {
    background: #d3dce6;
    font-weight: bold;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    line-height: 40px;
    height: 40px;
    min-height: 36px;
    margin: 8px 0;
    text-align: center;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .block{
    padding: 0;
    margin-bottom:0px;
  }
  .block-head{
    margin-top: 20px!important;
  }
</style>
