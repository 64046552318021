<template>
  <div class="editDialog">
    <el-dialog
      title=""
      :visible.sync="isShow"
      :width="width"
      top="30vh"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @close="cancel"
      @open="openDialog"
    >
      <div class="record">
        <p class="title">备注历史记录</p>
        <el-table border :data="records" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}" header-row-class-name="light-head">
          <el-table-column type="index" label="ID" align="center" width="80"></el-table-column>
          <el-table-column prop="createdAt" label="时间" align="center"></el-table-column>
          <el-table-column prop="content" label="历史备注" align="center"></el-table-column>
        </el-table>
<!--        <el-pagination-->
<!--          background-->
<!--          :page-sizes="pageSizes"-->
<!--          :page-size="10"-->
<!--          :current-page="currentPage"-->
<!--          layout="total, sizes, prev, pager, next"-->
<!--          :total="page.allNum"-->
<!--          @size-change="changeSize"-->
<!--          @current-change="changePage"-->
<!--        ></el-pagination>-->
      </div>
      <div class="memo-input">
        <p class="title">更新备注</p>
        <el-form ref="form" :rules="rules" :model="form" hide-required-asterisk>
          <el-form-item label="" prop="memo">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              maxlength="200"
              v-model="form.memo">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="bot hidden-sm-and-up">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
      <div slot="footer" class="hidden-xs-only">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import pagination from '@/mixins/pagination';
import service from "@/components/viewBar/api"
export default {
  // mixins: [pagination],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    flId:{
      type: Number,
      default: null
    }
  },
  data(){
    return{
      form:{
        memo:''
      },
      records: [],
      page: {},
      width: '40%',
      rules:{
        memo:[{required:true, message:"请输入备注", trigger:"blur"}]
      }
    }
  },
  methods:{
    getList(params){
      // 切换分页当前页码
      // let { pageNum = 1 } = params;
      // this.currentPage = pageNum;

      return service.remarkRecord({
        userId: this.flId,
        // page: this.currentPage,
        // pageSize: 10,
      }).then(res => {
        // this.page = res.page;
        // res.list.map((item, index) => {
        //   item.tempId = (params.pageNum - 1) * this.page.pageSize + index + 1;
        // });
        this.records = res.list;
      })
    },
    confirm(){
      let check = false;
      this.$refs.form.validate(res => check = res)
      if(!check) return;
      service.remark({userId:this.flId, memo:this.form.memo}).then(res => {
        // this.showDialog = false;
        this.form.memo = "";
        this.$notify({ type: 'success', message: '操作成功！' });
        this.getList({ pageNum: 1})
      })
      // this.$emit('editConfirm', this.memo);
    },
    //关闭
    cancel(){
      this.form.memo = "";
      this.$emit('editCancel')
    },
    openDialog(){
      // this.$refs.form.resetFields();
      this.getList()
      if(document.documentElement.clientWidth < 480){
        this.width = '80%'
      }
    }
  }
}
</script>

<style lang="scss">
  .editDialog{
    font-weight: normal;
    text-align: center;
    .title{
      color:#000000;
      font-size: 18px;
      text-align: left;
      margin-bottom:10px;
      margin-left:5px;
    }
    .memo-input{
      .title{
        margin-top:20px;
      }
    }
    .el-dialog__header{
      padding:0;
    }
    .el-dialog__body{
      padding-bottom: 0;
    }
    .el-dialog__footer{
      text-align: right!important;
    }
  }
@media screen and (max-width: 480px) {
  .el-dialog{
    margin-top: 15vh!important;
    max-height: 75%;
    overflow: auto;
  }
  .el-pagination{
    overflow: auto;
  }
  .el-col-16{
    width: 100%!important;
  }
}
</style>
