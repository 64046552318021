import API from "@/components/viewBar/api";

const tabs = [
  // { id: '', name: "基础信息", price: 0 },
  { id: 'UNIFY',name: "全景雷达", price: 0, api: API.commonRisk },
  { id: 'BEHAVIOR',name: "探针-C", price: 0, api: API.commonRisk },
  { id: 'ARCHIVES',name: "全景档案", price: 0, api: API.commonRisk },
  { id: 'HISTORY',name: "信用历史", price: 0, api: API.commonRisk },
  { id: 'BLACKLIST', name: "天创黑名单", price: 0, api: API.commonRisk },
  { id: 'BODYGUARD',name: "信贷保镖", price: 0, api: API.commonRisk },
  { id: 'PORTRAIT',name: "消费画像", price: 0, api: API.commonRisk },
  { id: 'EXPLORED',name: "探查综合", price: 0, api: API.commonRisk },
  // { id: 'LIGHTNING',name: "信用综合", price: 0, api: API.commonRisk },
  { id: 'STARLIGHT',name: "星耀报告(必查)", price: 0, api: API.commonRisk },
  { id: 'PURPLE',name: "高级报告", price: 0, api: API.commonRisk },
  { id: 'NOTE',name: "JT综合", price: 0, api: API.commonRisk },
  { id: 'MORE_NOTE',name: "JT多平台", price: 0, api: API.commonRisk }
]
if (process.env.VUE_APP_ADMIN && process.env.VUE_APP_ADMIN === 'true') {
  tabs.push({ id: 'history', name: "历史记录", price: 0, api: API.getHistory })
}
export const setPrice = (id, price) => {
  for (let idx in tabs) {
    if (tabs[idx].id === id)
      tabs[idx].price = price;
  }
}
export default tabs;
