<template>
  <div class="tabs">
    <div class="tabs-group fx-row">
      <template v-for="(item, index) in tabs">
        <div
          class="tab ft-sz-13 ft-bold"
          :class="{ active: activeIndex === item.id }"
          :key="index"
          @click="changeTab(item.id)"
        >
          {{ item.name }}(<span style="color: red;">{{item.price }}元</span>)
          <!-- <span class="c-red ft-sz-12" v-if="item.id == 'debt' || item.id == 'overdue'">NEW</span> -->
          <!-- <span class="c-whites ft-sz-12" v-if="item.price > 0"> -->
            
          <!-- </span> -->
          <!-- <span
            class="tab-refresh"
            v-if="item.price > 0 && activeIndex === item.id"
            @click.stop="$emit('refresh-tab', item.id)"
            ><i class="el-icon-refresh-left"></i
          ></span> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 *  若需要改动tabs,需要修改以下几处代码逻辑
 *  1. view-tabs.vue => tabs数组
 *  2. view-bar.vue => 切换tab的组件以及其他相应的activeIndex顺序
 */
import { mapMutations } from "vuex";
import tabs from '../tabs';
import {setPrice} from '../tabs';
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  created() {
    Promise.all(
      this.tabs.filter(tab => !tab.pro || this.pro).map((tab) => {
        let api = tab.api;
        if (!api) {
          return new Promise((res, _) => res(tab));
        }
        if (typeof api == "function") {
          api = [api];
        }
        return Promise.all(
          api.map(_ =>
            _({ uid: -1, price: "true" },tab.id).then(res => {
              return { price: res.amount / 100 };
            })
          )
        )
          .then(res =>
            res.reduce((a, b) => ({ ...a, price: a.price + b.price }))
          )
          .then(res => ({
            ...tab,
            ...res
          }))
          .then(r => {
            setPrice(r.id, r.price);
            return r;
          })
      })
    );
  },
  data() {
    return {
      tabs: tabs,
    };
  },
  methods: {
    ...mapMutations("userReport", ["setIndex", "setPrice"]),
    changeTab(index) {
      let price = this.tabs.filter((item, i) => item.id === index)[0].price;
      this.setPrice(price);
      this.$emit("change-tab", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  // margin-top: 15px;
  // padding: 36px 0 0 40px;
  // background-color: #fff;
  position: relative;
    // right: 5px;
    // top: 2px;
    margin-top: 25px;
    overflow: auto;
    z-index: 999;
    background-color: #F3F8FE;
  .tab {
    // min-width: 125px;
    padding: 14px 12px;
    height: 45px;
    margin-right: -1px;
    text-align: center;
    background-color: #e8f4ff;
    /* border-radius: 2px 2px 0 0; */
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #666666;
    cursor: pointer;
    white-space: nowrap;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border: 1px solid #d4dee8;
    &.active {
      // background-color: #1890ff;
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }
    .tab-refresh {
      margin-left: 0.2em;
    }
  }
}
@media screen and (max-width: 480px) {

.tabs-group{
  overflow-x: auto;

}
.tabs{
  padding: 1rem 0.5rem 0;

 .tab{
    min-width:9rem;
  }
}
}
</style>
