<template>
  <div class="block">
    <div class="block-head ft-sz-15 ft-bold">{{ title }}<span>{{desc}}</span></div>
    <div class="block-body">
      <slot></slot>
    </div>
    <div class="block-footer" v-if="showFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      deafult: ''
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    desc:{
      type: String,
      deafult: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.block {
  text-align: left;
  margin-bottom: 15px;
  &-head {
    padding: 18px 20px;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 2px;
    background-color: #fff;
    span{
      color:#A0A0A0;
    }
  }
  &-body {
    background-color: #fff;
    padding: 24px 20px;
  }
  &-footer {
    margin-top: 2px;
    background-color: #fff;
    padding: 13px 20px;
  }
}
</style>
