<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">天创黑名单记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div class="block c-bg-white">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">黑名单等级：</span><span></span>
        <span class="c-red ft-bold ft-sz-15" :style="{color:data1.color}">{{ data1.text }}</span>
      </div>
    </div>
    <el-row style="color: #fff;font-weight: bold;">
      <el-col :span="62"
        ><div class="grid-content bg-jihei">A - 极黑账户</div></el-col
      >
      <el-col :span="62"
        ><div class="grid-content bg-gaowei">B - 高危账户</div></el-col
      >
      <el-col :span="62"
        ><div class="grid-content bg-guanzhu">C - 关注账户</div></el-col
      >
      <el-col :span="62"
        ><div class="grid-content bg-huidu">D - 灰度账户</div></el-col
      >
      <el-col :span="62"><div class="grid-content bg-weimz">未命中</div></el-col>
    </el-row>
        <!--贷款基本情况-->
    <div class="block c-bg-white">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">贷款基本情况</span><span></span>
      </div>
    </div>
    <div>
      <el-row style="border: 1px solid #eee;">
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">贷款总笔数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">网络贷款机构类(现金贷)</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo border-none">贷款已结清机构数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01cczzzz }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_cpl0008 }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0002 }}</div></el-col>
      </el-row>
      <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">贷款交易总金额</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">贷款总机构数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo border-none">信用贷款时长</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01aczzzz }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_cpl0001 }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0045 }}</div></el-col>
      </el-row>

      <!-- <el-row>
        <el-col :span="24"><span>信用风险评分：{{ history.xyp_cpl0081 }}</span></el-col>
      </el-row> -->
    </div>
    <!--当前逾期情况-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">当前逾期情况</span><span></span>
      </div>
    </div>
      <el-row style="border: 1px solid #eee;">
        <el-col :span="12"><div class="grid-content bg-purple-dark5 fontBo">逾期机构数</div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark5">是否存在逾期未结清</div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple">{{ history.xyp_cpl0071 }}</div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0044==1?'逾期':'未逾期' }}</div></el-col>
      </el-row>
      <div style="text-align: start; color: red;font-size: 14px;">
        注：是否存在逾期未结清：未逾期，但近1，7，14，21...出现逾期情况，证明近一天内逾期又还款。
      </div>
      <div style="text-align: start; color: red;font-size: 14px;">
        注：是否存在逾期未结清：逾期，但近1，7，14，21...出现未逾期情况，证明之前更早逾期未还款，是指当前已逾期
      </div>


    <!--历史贷款机构履约情况-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">历史贷款机构履约情况</span><span></span>
      </div>
    </div>
      <el-row style="border: 1px solid #eee;">
        <el-col :span="12"><div class="grid-content bg-purple-dark5 fontBo">历史贷款机构成功还款笔数</div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-dark5">历史贷款机构交易失败笔数</div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple">{{ history.xyp_cpl0014 }}</div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0015 }}</div></el-col>
      </el-row>

    <!--交易失败向后距离下一次还款成功的天数-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">交易失败向后距离下一次还款成功的天数</span><span></span>
      </div>
    </div>
      <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">最大值</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">最小值</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo border-none">平均值</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_cpl0056 }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_cpl0059 }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0062 }}</div></el-col>
      </el-row>

    <!--因交易能力不足导致失败-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">因交易能力不足导致失败</span><span></span>
      </div>
    </div>
    <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">交易距离当前最小天数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">交易距离当前最大天数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo border-none">交易最小金额数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01bbzzza }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01bazzza }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple border-none">{{ history.xyp_t01abzzza }}</div></el-col>
      </el-row>

    <!--还款成功情况-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">还款成功情况</span><span></span>
      </div>
    </div>
    <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">还款总金额</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">还款金额最大值</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo border-none">交易距离当前最大天数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01aczzzc }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01aazzzc }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0068 }}</div></el-col>
      </el-row>

    <!--最近一次交易-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">最近一次交易</span><span></span>
      </div>
    </div>
    <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo">距离当前时间</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo">最近一次还款成功距离当前天数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo">交易失败机构数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo border-none">最后一次交易失败后还款次数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_cpl0046 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_cpl0068 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t03td148 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0069 }}</div></el-col>
      </el-row>

    <!--消费金融类-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">消费金融类</span><span></span>
      </div>
    </div>
    <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">总交易全额</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo">机构数(有场景的、分期)</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple-dark5 fontBo border-none">交易距离当天最小天数</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_t01aczzaz }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple">{{ history.xyp_cpl0007 }}</div></el-col>
        <el-col :span="8"><div class="grid-content bg-purple border-none">{{ history.xyp_t01bbzzaz }}</div></el-col>
      </el-row>

    <!--距离下一次还款成功天数-->
    <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">距离下一次还款成功天数</span><span></span>
      </div>
    </div>
    <el-row style="border: 1px solid #eee; margin-top: 0px;">
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo">距离下次还款成功最大天数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo">距离下次还款成功平均天数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo">距离下次还款成功最小天数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark5 fontBo border-none">最后一次交易失败后还款次数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_cpl0056 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_cpl0062 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_cpl0059 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple border-none">{{ history.xyp_cpl0069 }}</div></el-col>
      </el-row>
    <view-block title=".." style="display: none;">
      <el-row>
      </el-row>
    </view-block>
        <!--距离下一次还款成功天数-->
        <div class="block c-bg-white" style="margin-top: 20px;">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">历史记录</span><span></span>
      </div>
    </div>
    <template style="margin-bottom: 30px;">
      <el-table border :data="data2" style="width: 100%" header-row-class-name="light-head"
          :row-class-name="tableRowClassName">
        <el-table-column prop="label" label=""> </el-table-column>
        <el-table-column prop="val1" label="最近1天"></el-table-column>
        <el-table-column prop="val2" label="最近7天"> </el-table-column>
        <el-table-column prop="val3" label="最近14天"> </el-table-column>
        <el-table-column prop="val4" label="最近21天"> </el-table-column>
        <el-table-column prop="val5" label="最近30天"> </el-table-column>
        <el-table-column prop="val6" label="最近90天"> </el-table-column>
        <el-table-column prop="val7" label="最近180天"> </el-table-column>
      </el-table>

    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import viewBlock from "./view-block";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";

export default {
  mixins: [getReports],
  components: { DocumentGenerator, viewBlock },
  methods: {
    ...mapActions("userReport/blacklist", ["getBlacklist"]),
    load(refresh = false) {
      this.getBlacklist(refresh).then((res) => res && this.getReports());
    },
  },
  computed: {
    ...mapState("userReport", ["uid", "price"]),
    ...mapGetters("userReport/blacklist", ["data1","data2","history"]),
    ...mapState("userReport/blacklist", ["date"]),
  },
  created() {
    this.load()
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col-62 {
  width: 20% !important;
}
.el-col {
  border-radius: 0px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple-dark5 {
  background: #e2eaf3;
}
.bg-purple {
  background: #fefefe;
}
.bg-purple-light {
  background: #e5e9f2;
}
.bg-jihei {
  background: #fa4947;
}
.bg-gaowei {
  background: #ce760f;
}
.bg-guanzhu {
  background: #227cd7;
}
.bg-huidu {
  background: #22c3bc;
}
.bg-weimz {
  background: #489f12;
}
.el-col-6 {
    width: 25%!important;
}

.c-black{
  margin-bottom: 10px;
    font-size: 18px;
    margin-top: 10px;
}
.el-table td, .el-table th.is-leaf{
  border: 1px solid #EBEEF5;
}

.el-table td, .is-leaf{
  background: #99a9bf;
}

.el-table td{
  border-left: 1px solid #dcdcdc;
}

.fontBo{
  font-weight: bold;
}
.grid-content {
  height: 40px;
  line-height: 40px;
  // color: #111;
  font-size: 14px;
  border-right: 1px solid #dcdcdc;
  text-align: center;
}
.border-none{
  border-right: none;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
</style>
