<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">个人消费画像记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div>

      <el-row>
        <el-col :span="12">
          <el-container>
            <el-header>{{ data[0].label }}</el-header>
            <el-main>{{ data[0].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="12">
          <el-container>
            <el-header>{{ data[1].label }}</el-header>
            <el-main>{{ data[1].val }}</el-main>
          </el-container>
        </el-col>
      </el-row>
       <el-row style="margin-top: 20px;">
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[2].label }}</el-header>
            <el-main>{{ data[2].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[3].label }}</el-header>
            <el-main>{{ data[3].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[4].label }}</el-header>
            <el-main>{{ data[4].val }}</el-main>
          </el-container>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px;">
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[5].label }}</el-header>
            <el-main>{{ data[5].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[6].label }}</el-header>
            <el-main>{{ data[6].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[7].label }}</el-header>
            <el-main>{{ data[7].val }}</el-main>
          </el-container>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px;">
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[19].label }}</el-header>
            <el-main>{{ data[19].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[20].label }}</el-header>
            <el-main>{{ data[20].val }}</el-main>
          </el-container>
        </el-col>
        <el-col :span="8">
          <el-container>
            <el-header>{{ data[21].label }}</el-header>
            <el-main>{{ data[21].val }}</el-main>
          </el-container>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px;padding: 0 10px;">
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[8].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[8].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple-light">
            {{ data[9].label }}
          </div>
          <div class="grid-content bg-purple-light2">
            {{ data[9].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[10].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[10].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple-light">
            {{ data[11].label }}
          </div>
          <div class="grid-content bg-purple-light2">
            {{ data[11].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[12].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[12].val }}
          </div>
        </el-col>
      </el-row>
      <el-row style="padding: 0 10px;">
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[13].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[13].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple-light">
            {{ data[14].label }}
          </div>
          <div class="grid-content bg-purple-light2">
            {{ data[14].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[15].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[15].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple-light">
            {{ data[16].label }}
          </div>
          <div class="grid-content bg-purple-light2">
            {{ data[16].val }}
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[17].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[17].val }}
          </div>
        </el-col>
      </el-row>
      <!-- <el-row style="padding: 0 10px;">
        <el-col :span="5">
          <div class="grid-content bg-purple">
            {{ data[18].label }}
          </div>
          <div class="grid-content bg-purple2">
            {{ data[18].val }}
          </div>
        </el-col>
      </el-row> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"

export default {
  mixins: [getReports],
  components: { DocumentGenerator },
  methods: {
    ...mapActions("userReport/portrait", ["getPortrait"]),
    load(refresh = false) {
      this.getPortrait(refresh).then((res) => res && this.getReports());
    },
  },
  computed: {
    ...mapGetters("userReport/portrait", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/portrait", ["date"]),
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.with-margin {
  width: 50%;
  display: inline-block;

  .xfmc {
    display: inline-block;
    width: 55%;
    background-color: #ecf2f9;
    text-indent: 20px;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #dae2ea;
  }

  .shuzhi {
    display: inline-block;
    width: 45%;
    background-color: #f9f1ed;
    text-indent: 20px;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #e6ded9;
  }
}

.el-col-6 {
  width: 20% !important;
}

.el-col {
  border-radius: 0px;
}

.bg-purple-dark {
  background: #F3F8FE;
  border-bottom: 1px solid #e8eff6;
}

.bg-purple {
  background: #f4e5d9;
  border-bottom: 1px solid #ede8e4;
}

.bg-purple2 {
  background: #f7f3f0;
  border-bottom: 1px solid #ede8e4;
}

.bg-purple-light {
  background: #d3dce6;
  border-bottom: 1px solid #c6cfd9;
}

.bg-purple-light2 {
  background: #edf2f8;
  border-bottom: 1px solid #d3dce6;
}

.bg-jihei {
  background: #fa4947;
}

.bg-gaowei {
  background: #ce760f;
}

.bg-guanzhu {
  background: #227cd7;
}

.bg-huidu {
  background: #22c3bc;
}

.bg-weimz {
  background: #489f12;
}

.grid-content {
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  text-align: center;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    // margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

.radar {
  position: relative;

  &-score {
    width: 65%;
    margin-left: 20px;

    &-item {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;

      &-name {
        width: 75px;
        margin-right: 20px;
      }
    }

    &-tips {
      width: 30%;
      text-align: left;

      h4 {
        margin-bottom: 30px;
      }
    }
  }

  &-search {
    padding: 0 20px;

    .search-sum {
      width: 30%;

      &-top {
        padding: 13px 20px;
        margin-bottom: 2px;

        .sum-num {
          margin-left: 26px;
          color: rgba(64, 158, 255, 1);
        }
      }

      &-bottom {
        padding: 13px 30px;

        &-item {
          .item-num {
            color: rgba(64, 158, 255, 1);
          }
        }
      }
    }

    &-num-block {
      padding-top: 28px;
    }
  }

  &-current {
    padding-left: 20px;

    &-bottom {
      margin-bottom: 10px;

      &-left {
        width: 30%;
        padding: 50px 20px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .right-block {
        width: 24%;
        text-align: center;

        &-top {
          padding: 20px;
          margin-bottom: 5px;
        }

        &-bottom {
          padding: 10px;
        }
      }
    }
  }

  &-behavior {
    padding-left: 20px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}

.el-header,
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  margin: 0 10px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 20px;
  margin: 0 10px;
}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-col-5 {
  width: 20%;
}
.el-col-25 {
  width: 25%;
}
</style>
