/**
 *  页面渲染PDF、打印、预览实现
 *
 * @author tsln1998@gmail.com
 * @date 2020/08/25
 */
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {saveAs} from 'file-saver';

let canvas = [];

const reset = async () => {
  canvas = [];
}

const size = () => canvas.length;

const allocCanvasSpace = () => {
  canvas.push(true);
  return canvas.length - 1;
}

const addElement = async (element, index, comment) => {
  return html2canvas(element, {
    useCORS: true,
    logging: true,
    scrollY: -window.scrollY,
  }).then(c => {
    if (comment) {
      // 添加水印
      const ctx = c.getContext('2d');
      const fontSize = 72;
      // 文字长度 (对角线长度)
      const comments = comment.split(/\s+/);

      ctx.font = fontSize + "px microsoft yahei";
      ctx.fillStyle = "rgba(0,0,0,0.1)";
      ctx.strokeStyle = "rgba(0,0,0,0.4)";

      for (let y = fontSize; y < c.height; y += fontSize + fontSize * 4 * Math.random()) {
        const cc = comments[Math.floor(Math.random() * 1000) % comments.length];
        for (let x = -c.width * 0.5; x < c.width ; x += fontSize * cc.length * (1 + Math.random() * 4)) {
          if (Math.floor(Math.random() * 100) % 2 === 0) {
            ctx.fillText(cc, x, y);
          } else {
            ctx.strokeText(cc, x, y);
          }
        }
      }
    }
    if (index !== undefined)
      canvas[index] = c;
    else
      canvas.push(c);
  });
}

const toPDF = async () => {
  // A4 尺寸
  const PAGE_HEIGHT = 841.89;
  const PAGE_WIDTH = 595.28;
  // 创建PDF
  const pdf = new jsPDF('p', 'pt', 'a4').deletePage(1);
  // 页面管理
  let leftPageHeight = 0;
  const addPage = async () => {
    // 创建页面
    leftPageHeight = PAGE_HEIGHT - (pdf.getNumberOfPages() > 0 ? 2 : 6);
    return pdf.addPage();
  }
  // 遍历 Canvas
  for (let i = 0; i < canvas.length; i++) {
    let currentCanvas = canvas[i];
    // 计算当前纸张缩放比例
    const PT_PX_SCALE = PAGE_WIDTH / currentCanvas.width;
    // 计算当前 canvas 在纸张上的高度
    const height = PT_PX_SCALE * currentCanvas.height;
    // 已渲染高度
    let renderedHeight = 0;
    while (renderedHeight < height) {
      // 创建 pdf 页面
      let isNewPage = false;
      let page = pdf;
      if (leftPageHeight < 4) {
        isNewPage = true;
        page = await addPage();
      }
      // 渲染 PDF 页面
      page.addImage(
        currentCanvas, "JPEG",
        0, -renderedHeight + (PAGE_HEIGHT - leftPageHeight),
        PAGE_WIDTH, height,
      );
      // 打印页码
      if (isNewPage) {
        pdf.setFontSize(8);
        pdf.setTextColor('blue')
        pdf.text('Page ' + pdf.getNumberOfPages(), 2, 8);
      }
      // 计算已经渲染的高度
      const renderHeight = Math.min(height - renderedHeight, leftPageHeight);
      renderedHeight += renderHeight;
      leftPageHeight -= renderHeight;
    }
  }
  return pdf;
}

const toSingleCanvas = async (scale = 1.0) => {
  const target = document.createElement("canvas")
  // 这里不能 reduce, 最好还是 for 循环计算
  for (let i = 0; i < canvas.length; i++) {
    target.width = Math.max(target.width, canvas[i].width);
    target.height += canvas[i].height;
  }
  // 渲染
  let renderedHeight = 0;
  const ctx = target.getContext("2d");
  for (let i = 0; i < canvas.length; i++) {
    const currentCanvas = canvas[i];
    const left = (target.width - currentCanvas.width) * scale / 2;
    const top = renderedHeight;
    renderedHeight += currentCanvas.height * scale;
    // 转换图片并且绘制
    await new Promise((resolve, rej) => {
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, left, top, currentCanvas.width * scale, currentCanvas.height * scale)
        resolve()
      }
      image.src = currentCanvas.toDataURL('image/png', 1.0);
    })
  }
  return target;
}

const preview = async (scale = 0.1) => {
  return toSingleCanvas(scale).then(c => c.toDataURL('image/jpeg'))
}

const toImage = async (filename, scale = 1.0) => {
  return toSingleCanvas(scale).then(c => c.toBlob(blob => saveAs(blob, filename)))
}

export default {
  reset,
  size,
  allocCanvasSpace,
  addElement,
  preview,
  toPDF,
  toSingleCanvas,
  toImage,
}
