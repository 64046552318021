<template>
  <div class="num-block c-bg-cyan" :style="{width: width}">
    <div class="num-block-title ft-sz-14 ft-medium">{{title}}<noun-help :content="content" v-if="content"></noun-help></div>
    <div class="num-block-content">
      <span class="num-block-content-num ft-sz-25" :style="{color: numColor}">{{ num }}</span>
      <span class="num-block-content-unit ft-sz-14">{{ " " + unit }}</span>
    </div>
  </div>
</template>

<script>
/**
 * @param {String} title 卡片名称
 * @param {String, Number} num 数目
 * @param {String} numColor 数字颜色
 * @param {String} unit 单位
 * @param {String, Number} width 卡片宽度
 */

import nounHelp from "./noun-help"
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    num: {
      type: [Number, String],
      default: 0
    },
    numColor: {
      type: String,
      default: 'rgba(64, 158, 255, 1)'
    },
    unit: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    //名词解释内容
    content:{
      type: String,
      default: ""
    }
  },
  components:{
    nounHelp
  }
};
</script>

<style lang="scss" scoped>
.num-block {
  padding: 15px 0;
  text-align: center;
  margin-bottom: 0;
  &-title {
    margin-bottom: 17px;
  }
  &-content {
    text-align: center;
    &-num {
      color: rgba(64, 158, 255, 1);
    }
  }
}
@media screen and (max-width: 480px) {
  .num-block{
    width: 100%!important;
    margin-bottom: 0.5rem;
  }
}
</style>
