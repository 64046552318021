<template>
  <div class="view-head ft-bold c-dkGrey">
    <div class="info">
      <div class="info-btns" v-show="showBtn && !loading && !isAdmin">
        <div class="info-btn" v-if="!isOnlyMemo">
          <el-button type="primary" size="mini" class="btn" v-for="(item) in exam[auditStatus]" :key="item.name" @click="openEdit(item.name, item.clickName)">
            {{item.name}}
          </el-button>
        </div>
        <div class="info-btn" v-if="isOnlyMemo">
          <el-button type="primary" size="mini" class="btn" @click="openEdit('备注', 'handleRemark')">
           备注信息
          </el-button>
        </div>
      </div>
      <div class="info-head">
        <div class="title ft-sz-15 fx-v-center">
          <img class="title-icon" src="@/assets/userManage/avatar.png" />
          <span>UID: {{ userInfo.userId || ''}}
             <span class="blackTags" v-show="showBlack">
              <el-tag size="mini" type="info" effect="plain" v-for="(item,index) in blackTag" :key="index">{{item}}</el-tag>
            </span><noun-help v-if="blackTag.length" content="当前用户在系统中被关联商户选择的全部标签"/>
          </span>
        </div>
      </div>
      <div class="info-body">
        <div class="ft-sz-13 ft-bold fx-wrap">
          <div class="info-body-item">姓名: {{ userInfo.name }}</div>
          <div class="info-body-item">年龄：{{ age }}</div>
          <div class="info-body-item">手机号: {{ user.mobile }}</div>
          <div class="info-body-item">身份证号：{{ userInfo.idcard }}</div>
          <div class="info-body-item" v-if="firstLogin">注册设备：{{firstLogin.device }}</div>
          <div class="info-body-item">性别：{{ gender }}</div>
          <div class="info-body-item blackList">共享黑名单<noun-help content="当前用户是否被系统中其它商户拉入黑名单"/>：<span :class="isBlack ? 'c-red' : 'c-green'">{{isBlack ? '是' : '否'}}</span></div>
          <div class="info-body-item">注册时间：{{ userInfo.createdAt }}</div>
          <!-- <div style="margin-bottom: 14px">当前身份证匹配其他手机号码： <span v-if="!mobiles.length" class="c-green">无</span><span v-else class="fontNonmal c-red" v-for="(item, index) in mobiles" :key="index">{{item.mobile}}{{ index !== mobiles.length-1 ?',':''}} </span></div> -->
        </div>
         <!-- <div class="ft-sz-13 ft-bold fx-wrap riskInfo">
          <div class="info-body-item" v-for="(item, index) in riskInfo" :key="index">{{item.name}}: <span :class="item.result | mapRiskColor">{{ item.result | mapRiskStatus }}</span></div>
        </div> -->
      </div>
    </div>
    <edit-dialog
      :isShow="showDialog"
      :flId="userInfo.userId"
      @editConfirm="editConfirm"
      @editCancel="editCancel"
    >
    </edit-dialog>
<!--    审核拒绝， 加入黑名单弹框-->
    <refuse-dialog
      :isShowDialog="showReFuseDialog"
      :username="name"
      :status="dialogStatus"
      class="refuseDialog"
      @refuseConfirm="refuseConfirm"
      @refuseCancel="refuseCancel"
    >
    </refuse-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import {examUser} from '../reportBtn'
import editDialog from "@/components/viewBar/reports/editDialog";
import refuseDialog from "@/components/viewBar/reports/refuse-dialog";
import nounHelp from "@/components/viewBar/reports/noun-help"
import service from "@/components/viewBar/api"
var timer = null;
export default {
  components:{ editDialog, refuseDialog, nounHelp},
  computed: {
    ...mapState('userReport/verify', ['user', 'userInfo', 'firstLogin', 'mobiles', 'riskInfo']),
    ...mapState('userReport', ['auditStatus', 'memo', "flId", "isBlack", "loading", "blackTag", "showBlack"]),
    ...mapGetters('userReport/verify', ['gender', 'age']),
    ...mapGetters('userReport', ['chartLists'])
  },
  props:{
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      //按钮值
      exam : {},
      isAdmin: true,
      //只显示备注
      isOnlyMemo:true,
      showDialog: false,
      showReFuseDialog: false,
      name:'',
      dialogStatus:'',
    }
  },
  created(){
    switch(this.$route.path){
      case "/allUsers":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/users":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/passList":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/refuseList":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/blacklist":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/promote/setting":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      default:
        this.isOnlyMemo = true;
        this.exam = {};
        break;
    }
    this.isAdmin = process.env.VUE_APP_ADMIN && process.env.VUE_APP_ADMIN === "true"
  },
  filters: {
    mapRiskColor(r) {
      if (r === null)
        return 'c-grey';
      return r ? 'c-red' : 'c-green';
    },
    mapRiskStatus(r) {
      if (r === null)
        return '未命中';
      return r ? '是' : '否';
    },
  },
  methods:{
    ...mapMutations("userReport", ["setAuditStatus", "setMemo"]),
    ...mapActions("userReport/verify", ["getVerify"]),
    ...mapActions("userReport", ["getUserblacklist"]),
    openEdit(btnName, clickName){
      if(btnName === "备注"){
        this.showDialog = true;
      }else{
        this[clickName]()
      }
    },
    editConfirm(remark){
      this.handleRemark(remark)
    },
    editCancel(){
      this.showDialog = false;
    },
    //审核通过
    handlePass(){
      this.$alert(
        `是否确认将用户${this.userInfo.name }审核通过?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service.check({userId:this.userInfo.userId,status:1}).then(res => {
          this.showDialog = false;
          // 存储审核状态
          this.setAuditStatus(1);
          this.getVerify();
          this.getUserblacklist();
          this.$notify({ type: 'success', message: '操作成功！' });
        })
      }).catch(() =>{})
    },
    //审核拒绝
    handleRefuse(){
      this.name = this.userInfo.name;
      this.dialogStatus = 1;
      this.showReFuseDialog = true;
    },
    //加入黑名单
    addBlack(){
      this.name = this.userInfo.name;
      this.dialogStatus = 0;
      this.showReFuseDialog = true;
    },
    //移出黑名单
    delBlack(){
      this.$alert(
        `是否确认将用户${this.userInfo.name }移出黑名单?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service.check({userId: this.userInfo.userId, status: 0}).then(res => {
          this.showDialog = false;
          this.setAuditStatus(0);
          this.getVerify();
          this.getUserblacklist();
          this.$notify({type: 'success', message: '操作成功！'});
        })
      }).catch(() =>{})
    },
    //备注
    handleRemark(remark){
      service.remark({userId:this.userInfo.userId, memo:remark}).then(res => {
        this.showDialog = false;
        this.setMemo(remark);
        this.getVerify();
        this.$notify({ type: 'success', message: '操作成功！' });
      })
    },
    //审核拒绝，加入黑名单
    refuseConfirm(reason){
      let status = null;
      if(this.dialogStatus){
        status = 2;
      }else{
        status = -1;
      }
      service.check({userId:this.userInfo.userId, status, reason:reason }).then(res => {
        this.showReFuseDialog = false;
        this.setAuditStatus(status);
        this.getVerify();
        this.getUserblacklist();
        this.$notify({ type: 'success', message: '操作成功！' });
      })
    },
    refuseCancel(){
      this.showReFuseDialog = false;
    }
  },
  watch:{
    isBlack(newValue, oldValue){
        this.isBlack = newValue;
    },
    blackTag(newValue, oldValue){
        this.blackTag = newValue;
    },
  }
};
</script>

<style lang="scss" scoped>
.view-head {
  text-align: left;
  .title {
    margin-bottom: 15px;
    img {
      width: 24px;
      height: auto;
      margin-right: 13px;
    }
  }
  .info {
    padding: 20px 20px 8px;
    background-color: #fff;
    .blackTags{
      margin-left:10px;
      margin-right:2px;
      .el-tag{
        color: #FFFFFF;
        border-color: #fe0101;
        background: #fe0101;
      }
      .el-tag + .el-tag{
        margin-left:10px;
      }
    }
    &-body {
      &-item {
        width: 25%;
        margin-bottom: 14px;
        white-space: nowrap;
        color: rgba(76, 76, 76, 1);
      }
      .blackList{
        color:#409EFF;
      }
      .riskInfo{
        border-top: 2px solid #f7f8fc;
        padding-top:14px;
      }
    }
    .info-btns{
      position:fixed;
      top:500px;
      right:269px;
      z-index:9999;
      width:50px;
      clear:both;
      .info-btn{
        .btn{
          float:left;
          &:nth-child(1){
          margin-bottom:10px;
        }
        }
        .btn +.btn{
          margin:0;
          margin-bottom:10px;
        }
      }
      .el-button--primary{
        &:hover{
          background-color:#1E6FFF;
          border-color:#1E6FFF;
        }
      }
      .el-button--mini{
        padding: 13px 8px;
      }
    }
  }
  .fontNonmal{
    font-weight: 500;
  }
  // .risk {
  //   margin-top: 15px;
  //   &-head {
  //     padding: 18px 20px 0 20px;
  //     background-color: #fff;
  //     margin-bottom: 2px;
  //   }
  //   &-body {
  //     padding: 28px 80px;
  //     background-color: #fff;
  //   }
  // }
}
@media screen and (max-width: 480px) {

  .view-head {
    .info{
      padding: 1.25rem;
      .info-btns{
            top: auto;
            right: 1rem;
      }
    }
    .info-body-item{
      width: 100%;
      font-size: 14px;
    }
    .title{

    }
  }
}
</style>
