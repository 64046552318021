<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">借条综合记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <el-row :gutter="12">
      <el-col :span="8" v-for="(it,index) in data" :key="index">
        <div class="cbody" shadow="always">
          <span class="name">{{ it.title }}</span><span :class="it.result==1?'tag-red':'tag-green'">{{ it.result==1?'存在':'不存在' }}</span>
        </div>
      </el-col>
    </el-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";

export default {
  mixins: [getReports],
  components: {
    DocumentGenerator
  },
  methods: {
    ...mapActions("userReport/note", ["getNote"]),
    load (refresh = false) {
      this.getNote(refresh).then((res) => res && this.getReports())
    },
  },
  computed: {
    ...mapGetters("userReport/note", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/note", ["date"])
  },
  created () {
    this.load();
  },
};
</script>

<style lang="scss" scoped>

.text-row{
  // margin: 10px 50px 10px 10px;
}
.el-card__body{
  padding: 5px!important;
}
.name{
  font-weight: bold;
  color: #000; 
  font-size: 14px;    
  white-space: normal;
  display: inline-block;
  width: max-content;
}
.cbody{
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tag-red{
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.tag-green{
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}
</style>