import API from "../api";

export default {
  methods: {
    /**
     * 原来的逻辑是购买报告，剩余报告数
     * 现在的逻辑是充值，查询账户余额
     * 由于项目中多处需要用到了这个mixin，为了不修改之前的文件，此处命名getReports保持不变
     */

    // 获取剩余报告数
    getReports() {
      /*
      return API.requestPost({
        url: "/user/superInfo"
      }).then(res => {
        let {
          amount = 0, promote = 0
        } = res;
        // this.$store.commit("setReports", reports);
        this.$store.commit("setBalance", amount);
        this.$store.commit("setPromoteCoin", promote);
      });
       */
    }
  }
};
