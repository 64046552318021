<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">历史信用记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <el-row>
      <el-col :span="6"><div class="grid-content bg-purple-light">交易信息</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
    </el-row>
    <radar>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01acizzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01acjzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ackzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aclzzz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最大金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aaizzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aajzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aakzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aalzzz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易平均金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adizzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adjzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adkzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adlzzz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易机构去重数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01deizzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01dejzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01dekzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01delzzz }}</div></el-col>
      </el-row>
    </radar>
    <el-row style="margin-top: 20px;">
      <el-col :span="6"><div class="grid-content bg-purple-light">还款成功信息</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
    </el-row>
    <radar title="还款成功信息">
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01acizzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01acjzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ackzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aclzzc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款最大金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aaizzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aajzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aakzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aalzzc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款最小金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abizzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01abjzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abkzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ablzzc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款平均金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adizzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adjzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adkzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adlzzc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ccizzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ccjzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01cckzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01cclzzc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">机构去重数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01deizzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01dejzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01dekzzc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01delzzc }}</div></el-col>
      </el-row>
    </radar>
    <el-row style="margin-top: 20px;">
      <el-col :span="6"><div class="grid-content bg-purple-light">当前次数还款情况</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
    </el-row>
    <radar title="当前次数还款情况">
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">成功还款金额占比</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02acizzc_acizzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02acjzzc_acjzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ackzzc_ackzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02aclzzc_aclzzz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">成功还款笔数占比</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t0400001 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t0400002 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t0400003 }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t0400004 }}</div></el-col>
      </el-row>
    </radar>
    <el-row style="margin-top: 20px;">
      <el-col :span="6"><div class="grid-content bg-purple-light">因交易能力不足导致失败</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
      <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
    </el-row>
    <radar title="因交易能力不足导致失败">
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ccizza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ccjzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01cckzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01cclzza }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01acizza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01acjzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01cckzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aclzza }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最大金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aaizza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aajzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aakzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aalzza }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最小金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abizza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01abjzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abkzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ablzza }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">笔数/总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ccizza_cczzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02ccjzza_cczzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02cckzza_cczzza }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02cclzza_cczzza }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">金额/总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02acizza_acizzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02acjzza_acjzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ackzza_ackzzz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02aclzza_aclzzz }}</div></el-col>
      </el-row>
    </radar>
    <radar title="小贷担保类信息">
      <el-row style="margin-top: 20px;">
        <el-col :span="6"><div class="grid-content bg-purple-light">小贷担保类信息</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01acizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01acjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ackzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aclzbz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易平均金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adkzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adlzbz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最大金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aaizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aajzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aakzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aalzbz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最小全额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01abjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abkzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ablzbz }}</div></el-col>
      </el-row>
    </radar>
    <radar title="小贷担保类还款成功信息">
      <el-row style="margin-top: 20px;">
        <el-col :span="6"><div class="grid-content bg-purple-light">小贷担保类还款成功信息</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01acizbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01acjzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ackzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aclzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款平均金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adizbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adjzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01adkzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01adlzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款最大金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aaizbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aajzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01aakzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aalzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款最小全额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abizbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01abjzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abkzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ablzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ccizbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ccjzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01cckzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01cclzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款机构去重数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01deizbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01dejzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01dekzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01delzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">笔数/总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ccizbc_cczzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02ccjzbc_cczzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02cckzbc_cczzbc }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02cclzbc_cczzbc }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">金额/总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02acizbc_acizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02acjzbc_acjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ackzbc_ackzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02aclzbc_aclzbz }}</div></el-col>
      </el-row>
    </radar>
    <radar title="小贷担保类因交易能力不足导致失败">
      <el-row style="margin-top: 20px;">
        <el-col :span="6"><div class="grid-content bg-purple-light">小贷担保类因交易能力不足导致失败</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">近5次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">近20次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">近50次</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">近100次</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01acifba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01acjfba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01ackfba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01aclfba }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易平均金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最大金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark"></div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易最小金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abifba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01abjfba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01abkfba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01ablfba }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">交易总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02acizbc_acizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02acjzbc_acjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ackzbc_ackzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02aclzbc_aclzbz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">还款机构去重数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01deihba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01dejhba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t01dekhba }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t01delhba }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">笔数/总笔数</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ccizba_ccizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02ccjzba_ccjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02cckzba_cckzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02cclzba_cclzbz }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><div class="grid-content bg-purple-light2">金额/总金额</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02acizba_acizbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02acjzba_acjzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple-dark">{{ history.xyp_t02ackzba_ackzbz }}</div></el-col>
        <el-col :span="6"><div class="grid-content bg-purple">{{ history.xyp_t02aclzba_aclzbz }}</div></el-col>
      </el-row>
    </radar>
    <radar-block title="小贷担保类因交易能力不足导致失败" style="display: none;"></radar-block>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import radarBlock from "./radar-block";

export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock },
  methods: {
    ...mapActions("userReport/history", ["getHistory"]),
    load(refresh = false) {
      this.getHistory(refresh).then((res) => res && this.getReports());
    },
  },
  computed: {
    ...mapGetters("userReport/history", ["history"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/history", ["date"]),
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col-6 {
  width: 20% !important;
}
.el-col {
  border-radius: 0px;
}
.bg-purple-dark {
  background: #F3F8FE;
  border-bottom: 1px solid #e8eff6;
}
.bg-purple {
  background: #f7f3f0;
  border-bottom: 1px solid #ede8e4;
}
.bg-purple-light {
  background: #d3dce6;
  border-bottom: 1px solid #c6cfd9;
}
.bg-purple-light2 {
  background: #dbe6f3;
  border-bottom: 1px solid #d3dce6;
}
.bg-jihei {
  background: #fa4947;
}
.bg-gaowei {
  background: #ce760f;
}
.bg-guanzhu {
  background: #227cd7;
}
.bg-huidu {
  background: #22c3bc;
}
.bg-weimz {
  background: #489f12;
}
.grid-content {
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  color: #000;
  font-size: 14px;
  text-align: center;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
</style>
