<template>
  <div class="verify" id="verify-tab">
    <document-generator/>
    <view-block :title="'活体认证'" style="display: none;">
      <div class="verify-live">
        <div class="ft-sz-13">
          <span class="c-blue ft-bold ft-sz-15">OCR活体检测结果：</span>
          <span>对比相似度：</span>
          <span class="c-blue similar">
            <span class="ft-sz-20 ft-wt-900">{{ userInfo.similarity || ''}}分</span>
            <span class="ft-bold">(分值越高越安全)</span>
          </span>
        </div>
        <div class="verify-live-img fx-m-between">
          <div class="card" v-for="(item, index) in idCardList" :key="index">
            <el-image class="card-img" :src="item.img" :preview-src-list="cardImgList">
              <div
                slot="error"
                class="image-slot"
                :style="{'backgroundImage': 'url(' + item.defaultImg + ')'}"
              ></div>
            </el-image>
            <div class="c-dkGrey ft-sz-13" style="margin-bottom: 20px;">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </view-block>
    <view-block style="margin-bottom: 0;">
      <!-- <div class="verify-info">
        <ul class="fx-wrap c-dkGrey ft-sz-13 ft-bold">
          <li v-for="(item, index) in baseInfo" :key="index">
            <span>{{ item.label }}</span>
            <span class="c-dkGrey">{{ item.desc }}</span>
          </li>
        </ul>
      </div> -->
      <el-empty description=" ">请根据需求查看对应报告</el-empty>
    </view-block>
    <view-block :title="'负债情况'" desc="（此内容为用户自主填写，非查询数据，需要您自己辨别真伪）" style="display: none;">
      <div v-if="debtStatus">
        <el-table
          border
          :data="debtList1"
          style="width: 100%; margin-bottom:20px; color:#A0A0A0"
          header-row-class-name="light-head"
          row-class-name="ft-sz-13"
        >
          <el-table-column prop="creditCardNum" label="本人名下信用卡" align="center">
            <template slot-scope="scope">
              {{scope.row.creditCardNum}}张
            </template>
          </el-table-column>
          <el-table-column prop="creditCardLimitTotal" label="本人名下信用卡总额度" align="center">
            <template slot-scope="scope">
              {{scope.row.creditCardLimitTotal}}万
            </template>
          </el-table-column>
          <el-table-column prop="debtTotal" label="当前网贷总负债" align="center">
            <template slot-scope="scope">
              {{scope.row.debtTotal}}万
            </template>
          </el-table-column>
        </el-table>
        <el-table
          border
          :data="debtList2"
          style="width: 100%; margin-bottom:20px; color:#A0A0A0"
          header-row-class-name="light-head"
          row-class-name="ft-sz-13"
        >
          <el-table-column prop="jdbWaitRepay" label="借贷宝待还" align="center">
            <template slot-scope="scope">
              {{scope.row.jdbWaitRepay}}万
            </template>
          </el-table-column>
          <el-table-column prop="jdbHasRepay" label="借贷宝已还" align="center">
            <template slot-scope="scope">
              {{scope.row.jdbHasRepay}}万
            </template>
          </el-table-column>
          <el-table-column prop="todayWaitRepay" label="今日待还总额" align="center">
            <template slot-scope="scope">
              {{scope.row.todayWaitRepay}}万
            </template>
          </el-table-column>
        </el-table>
        <el-table
          border
          :data="debtList3"
          style="width: 100%; margin-bottom:20px; color:#A0A0A0"
          header-row-class-name="light-head"
          row-class-name="ft-sz-13"
        >
          <el-table-column prop="jjdWaitRepay" label="今借到待还" align="center">
            <template slot-scope="scope">
              {{scope.row.jjdWaitRepay}}万
            </template>
          </el-table-column>
          <el-table-column prop="jjdHasRepay" label="今借到已还" align="center">
            <template slot-scope="scope">
              {{scope.row.jjdHasRepay}}万
            </template>
          </el-table-column>
          <el-table-column prop="overdueTotal" label="历史总逾期" align="center">
            <template slot-scope="scope">
              {{scope.row.overdueTotal}}次
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <p style="text-align: center; color:#909399">该用户未进行资产负债认证, 暂无数据</p>
      </div>
    </view-block>
    <view-block :title="'APP匹配分析'" style="display: none;">
<!--      <div class="fx-wrap">-->
<!--        <div-->
<!--          class="verify-app c-dkGrey ft-sz-13"-->
<!--          :class="{isHit : item.isHit}"-->
<!--          v-for="(item, index) in appInfo"-->
<!--          :key="index"-->
<!--        >{{ item.name }}</div>-->
<!--      </div>-->
      <el-table
        border
        :data="appInfo"
        style="width: 100%"
        header-row-class-name="light-head"
        row-class-name="ft-sz-13 ft-bold"
      >
        <el-table-column prop="category" label="APP分类" align="center" width="120">
          <template slot-scope="scope">
            {{`${scope.row.category}(${scope.row.name.length})`}}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="APP名称" align="center">
          <template slot-scope="scope">
            <div class="fx-wrap">
              <div
                class="verify-app c-dkGrey ft-sz-12"
                v-for="(item, index) in scope.row.name"
                :key="index"
              >{{ item }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </view-block>
    <view-block :title="'登录信息'" style="display: none;">
      <el-table
        border
        :data="loginList"
        style="width: 100%"
        header-row-class-name="light-head"
        row-class-name="ft-sz-13 ft-bold"
      >
        <el-table-column prop="createdAt" label="登录时间" align="center"></el-table-column>
        <el-table-column prop="ip" label="IP地址" align="center"></el-table-column>
        <el-table-column prop="address" label="GPS城市" align="center"></el-table-column>
        <el-table-column prop="deviceVendor" label="手机厂商" align="center"></el-table-column>
        <el-table-column prop="device" label="手机型号" align="center"></el-table-column>
<!--        <el-table-column label="系统版本号" align="center">-->
<!--          <template slot-scope="scope">{{ scope.row.deviceOs + scope.row.deviceOsVersion}}</template>-->
<!--        </el-table-column>-->
        <el-table-column prop="version" label="系统版本号" align="center"></el-table-column>
<!--        <el-table-column prop="deviceId" label="设备ID" align="center" min-width="150"></el-table-column>-->
      </el-table>
    </view-block>
    <view-block :title="'地图定位'" style="display: none;">
      <div class="peoples">
        用户认证经度{{this.rangeData.lon || ''}},纬度{{this.rangeData.lat || ''}},认证范围
        <el-dropdown @command="rangeClick">
          <span class="el-dropdown-link">
            {{this.range}}米<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="100">100米</el-dropdown-item>
            <el-dropdown-item command="200">200米</el-dropdown-item>
            <el-dropdown-item command="300">300米</el-dropdown-item>
            <el-dropdown-item command="400">400米</el-dropdown-item>
            <el-dropdown-item command="500">500米</el-dropdown-item>
            <el-dropdown-item command="600">600米</el-dropdown-item>
            <el-dropdown-item command="700">700米</el-dropdown-item>
            <el-dropdown-item command="800">800米</el-dropdown-item>
            <el-dropdown-item command="900">900米</el-dropdown-item>
            <el-dropdown-item command="1000">1000米</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        之内除当前用户外，已有<span :class="this.rangeData.count > 0 ? 'c-red ft-bold' : ''" class="person">{{this.rangeData.count || 0}}</span>人</div>
      <el-table
        border
        :data="mapList"
        style="width: 100%"
        header-row-class-name="light-head"
        row-class-name="ft-sz-13 ft-bold"
        max-height="500"
        size="mini"
      >
        <el-table-column prop="createdAt" label="登录时间" align="center"></el-table-column>
        <el-table-column prop="lon" label="经度" align="center"></el-table-column>
        <el-table-column prop="lat" label="纬度" align="center"></el-table-column>
        <el-table-column prop="address" label="城市" align="center"></el-table-column>
        <el-table-column label="查看" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="drawMap(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        :page-sizes="pageSizes"
        :page-size="10"
        layout="total, sizes, prev, pager, next"
        :total="page.allNum"
        @size-change="changeSize"
        @current-change="changePage"
      ></el-pagination>
      <div class="verify-map" id="login-map"></div>
    </view-block>
  </div>
</template>

<script>
import viewBlock from './view-block';

import {mapGetters, mapState, mapActions, mapMutations} from 'vuex';
import pagination from '@/mixins/pagination';
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [pagination],
  props: {
    similar: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      showImage: false,
    };
  },
  components: {
    DocumentGenerator,
    viewBlock
  },
  computed: {
    ...mapState('userReport/verify', ['userInfo', 'appInfo', 'mapList', 'page', 'loginList', 'rangeData']),
    ...mapState('userReport', ['range', 'debtStatus']),
    ...mapGetters('userReport/verify', ['idCardList', 'cardImgList', 'baseInfo','debtList1', 'debtList2', 'debtList3'])
  },
  methods: {
    ...mapActions('userReport/verify', ['drawMap', 'getPerson']),
    ...mapMutations("userReport", ["setRange"]),
    rangeClick(command){
      this.range = command;
      this.setRange(command);
      this.getPerson(command)
    }
  }
};
</script>

<style lang="scss" scoped>
.verify {
  &-live {
    .similar {
      margin-right: 20px;
    }
    &-img {
      padding: 24px 8% 24px 18px;
      .card {
        width: 300px;
        height: 340px;
        text-align: center;
        border: 3px solid rgba(247, 248, 252, 1);
        &-img {
          width: 100%;
          height: 190px;
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &-info {
    li {
      min-width: 33%;
      margin-bottom: 20px;
    }
  }
  &-app {
    padding: 1px 5px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid rgba(160, 160, 160, 1);
    margin-right: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    font-weight: normal;
    //&.isHit {
    //  border: 1px solid red;
    //  color: red;
    //}
  }
  .tip {
    background-color: rgba(217, 243, 214, 1);
    padding: 10px 22px;
    margin-bottom: 20px;
  }
  .verify-map {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
    border: 5px solid rgba(236, 242, 249, 1);
  }
  img.el-image-viewer__img {
    transform: scale(1) rotate(0) !important;
  }
  .peoples{
    background-color: rgb(217,243,214);
    padding:10px;
    margin-bottom:10px;
    .el-dropdown-link {
      cursor: pointer;
      color: #409EFF;
    }
    .person{
      padding:0 5px;
    }
  }
}
@media screen and (max-width: 480px) {
  .verify-live-img{
    display: block;
  }
  .card{
    width: 100%!important;
    height: auto!important;
    margin-bottom: 1rem;
    &-img {
      margin-top: 1.5rem!important;;
      margin-bottom: 1rem!important;;
    }
  }
  .verify-info li{
    display: block;
    width: 100%;
  }


}
.block-head{
    margin-bottom: 0!important
  }
</style>
