<template>
  <div class="block c-bg-white">
    <div class="block-head fx-v-center">
      <div class="line"></div>
      <span class="c-black ft-bold ft-sz-15">{{title}}</span>
      <span v-if="tip" class="tip ft-sz-12 c-grey">{{ tip }}</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.block {
  padding: 26px 20px 30px 20px;
  margin-bottom: 15px;
  &-head {
    height: 20px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
    .line {
      width: 4px;
      height: 20px;
      background-color: rgba(64, 158, 255, 1);
      border-radius: 2px;
      margin-right: 15px;
    }
    .tip {
      margin-left: 10px;
    }
  }
}
@media screen and (max-width: 480px) {
  .block {
    &-head{
      height: auto;
    }
  }
}

</style>