<template>
  <div style="display: none;">
    <el-button
    class="hidden-xs-only"
      style="float: right; margin-right: 1em; margin-top: 1em;"
      size="mini"
      icon="el-icon-download"
      @click="download"
    >下载PDF
    </el-button>
  </div>
</template>

<script>
import canvas from "@/mixins/canvas";
import dayjs from 'dayjs';
import tabs from '../tabs';

import {mapState} from "vuex";

export default {
  props: {
    split: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: [],
      loadingContext: false,
    };
  },
  computed: {
    ...mapState('userReport/verify', ['user', 'userInfo']),
    ...mapState('userReport', ['activeIndex']),
  },
  watch: {
    loading(o, n) {
      if (n.length > 0 && !this.loadingContext) {
        console.log('show loading')
        this.loadingContext = this.$loading({
          lock: true,
          text: '数据处理中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else if (n.length === 0 && this.loadingContext) {
        console.log('close loading')
        this.loadingContext.close();
        this.loadingContext = false;
      }
    }
  },
  methods: {
    add(e) {
      try {
        let el = e.target;
        while (el !== document.body) {
          if (el.className.indexOf('document-view') >= 0)
            break;
          el = el.parentElement;
        }
        if (el) {
          // 遍历子元素添加 绕过bug
          const queue = [];
          if (this.split) {
            for (let i = 0; i < el.children.length; i++) {
              queue.push({el: el.children[i], index: canvas.allocCanvasSpace()});
            }
          } else {
            queue.push({el: el, index: canvas.allocCanvasSpace()});
          }
          this.loading.push({});
          return Promise.all(queue.map(item => canvas.addElement(item.el, item.index)))
            .catch(() => {
            })
            .then(() => this.loading.pop());
        }
      } catch (e) {
      } finally {
      }
      return new Promise((res, rej) => rej());
    },
    download(e) {
      try {
        // 生成文件名
        const fn = [];
        fn.push(this.userInfo.name);
        fn.push(this.user.mobile);
        if (this.activeIndex !== undefined && tabs.hasOwnProperty(this.activeIndex)) {
          const tab = tabs[this.activeIndex];
          if (tab)
            fn.push(tab.name);
        }
        fn.push(dayjs().format('YYYYMMDDHHmmss'));
        const filename = fn.join('-').replace(/\*/g, 'x');
        // 开始输出
        return canvas.reset()
          .then(() => this.loading.push(true))
          .then(() => this.add(e))
          .then(() => canvas.toPDF())
          .then(pdf => pdf.save(filename))
          .then(() => canvas.reset())
          .catch(() => {
          })
          .then(() => this.loading.pop());
      } catch (e) {
      } finally {
      }
    },
  }
}
</script>

<style scoped>

</style>
